import axios from 'axios'

import store from '@/store'
import { getToken } from '@/utils/auth'
// import router from '@/router/index.js'

// 创建一个axios实例
const service = axios.create({
    baseURL: '' //接口地址
})

// 请求拦截器
service.interceptors.request.use(
    config => {
       
        config.headers.Authorization = 'Bearer ' + store.getters.token
        return config
    },
    error => {
        console.log(error)
        return Promise.reject(error)
    }
)

// 响应拦截器
service.interceptors.response.use(
    response => {
        const res = response.data

        // 与后端约定的错误码
        if (res.code !== 200) {
            
                // 现约定 50001:无效token 50002:token过期
            if (res.code === 50001 || res.code === 50002) {
                Dialog.alert({
                    title: '提示',
                    message: '您还未登录或登录已过期，请重新登录'
                }).then(() => {
                    store.dispatch('user/resetToken').then(() => {
                        location.reload()
                    })
                })
            }

            return Promise.reject(new Error(res.message || 'Error'))
        } else {
            return res
        }
    },
    error => {
        console.log('err' + error)
        if(error.message == "timeout of 5000ms exceeded"){
           
        }
        // Toast.fail(error.message)
        // return Promise.reject(error)
        return Promise.reject(error)
    }
)

export default service